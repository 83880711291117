@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kaisei+Decol:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: ease-in-out;
  scroll-behavior: smooth;
}

/* App.css */
body {
  overflow-x: hidden;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000; /* Customize the background color */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 1;
}
.preloader-content {
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* You can add additional styles for your loading percentage text or other elements */
.preloader-content p {
  font-size: 350px;
  color: transparent;
  -webkit-text-stroke: 2px white;
  opacity: 0.5;
}

.App {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 3rem;
  height: 100vh;
  background-color: #2d4059;
}

.about {
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 3rem;
  height: 100vh;
  background-color: #2d4059;
}

.product {
  position: relative;
  display: flex;
  width: 100vw;
  flex-direction: column;
  padding: 2rem 3rem;
  height: 100%;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)),
    url("../src/assets/fp2.jpeg");
  background-repeat: no-repeat;
  background-size: contain; */
  background-color: #2d4059;
  justify-content: center;
}

.contact {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 2rem 5rem;
  height: 100vh;
  background-color: #2d4059;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
}

.foot {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 3rem;
  height: 100%;
  background-color: #2d4059;
}

/* contoh */

.bottom-nav {
  display: none;
}
/* contoh */

.footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.footer .rightfooter {
  color: #ffd460;
  text-align: end;
}

.footer .rightfooter p {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
}

.footer .rightfooter .readMore {
  font-size: 12px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  color: #ffd460;
  transition: color 0.3s;
  font-family: "Raleway", sans-serif;
}

.footer .rightfooter .readMore::after {
  content: "";
  display: block;
  width: 0; /* Start with zero width */
  height: 1px;
  background-color: #ffd460;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.3s; /* Add a smooth transition effect */
}

.footer .rightfooter .readMore:hover::after {
  width: 100%; /* Expand the width to 100% on hover */
}

.header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header .logo img {
  width: 100px;
}

.menu-icon {
  display: none; /* Hide the menu icon by default */
  cursor: pointer;
  z-index: 2;
}

.menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: #ffd460;
  margin: 6px 0;
  transition: 0.4s;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar li {
  margin-right: 5px;
  list-style-type: none;
  text-transform: uppercase;
  border: 1px solid #ffd460;
  border-radius: 20px;
  padding: 2px 17px;
}

.navbar a {
  text-decoration: none;
  color: #ffd460;
  font-family: "Raleway", sans-serif;
}

.navbar li:hover {
  background-color: #ffd460;
  color: #2d4059;
  transition: background-color 0.5s ease-in;
}

.navbar .home,
.navbar .about-navbar,
.navbar .product-navbar,
.navbar .contact-navbar {
  margin-right: 10px;
  background-color: none;
  color: #ffd460;
  font-size: 15px;
}

.content {
  width: 100%;
}

.content {
  padding-left: 190px;
}

.minajoText {
  color: #ffd460;
  text-transform: uppercase;
  font-size: 15vw;
  font-family: "Raleway", sans-serif;
  /* margin-top: 50px; */
}

.indoText {
  color: #ffd460;
  text-transform: uppercase;
  font-size: 1.5vw;
  font-family: "Raleway", sans-serif;
  margin-left: 10px;
  margin-top: -20px;
}

.about-content {
  margin-top: 10px;
  width: 100%;
  color: #ffd460;
}

.about-content h1 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 70px;
}

.about-content p {
  width: 100%;
  font-family: "Bebas Neue", sans-serif;
  font-size: 7.5vh;
  font-weight: 400;
}

.button-about {
  display: flex;
  justify-content: flex-end;
}

.button-about-content {
  display: flex;
  flex-direction: row;
  border: 1px solid #ffd460;
  border-radius: 20px;
  width: 125px;
  height: 36px;
  justify-content: center;
  align-items: center;
}

.button-about-content:hover {
  background-color: #ffd460;
  color: #2d4059;
  transition: background-color 0.7s;
}

.button-about-content p {
  /* margin-right: 5px; */
  font-family: "Bebas Neue", sans-serif;
}

.button-about a {
  text-decoration: none;
  color: #ffd460;
}

.product .product-line {
  width: 100%;
  height: 100%;
  border: 1px solid #ffd460;
  border-radius: 20px;
}

.product .product-title p {
  font-family: "Raleway", sans-serif;
  font-size: 5vw;
  color: transparent;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-top: 20px;
  -webkit-text-stroke-color: #ffd460;
  -webkit-text-stroke-width: 2px;
}

.product-line-content-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* margin: 0 80px; */
}

/* Product item styles */
.product-line-content-wrap .product-line-content {
  position: relative;
  margin: 30px;
  border-radius: 20px;
  width: 450px;
  height: 100%;
}

.product-line-content-wrap .product-line-description {
  position: relative;
  margin: 30px;
  border-radius: 20px;
  width: 450px;
  color: white;
}

.product-line-content h3 {
  position: absolute; /* Add absolute positioning */
  bottom: 0; /* Adjust the positioning as needed */
  left: 0; /* Adjust the positioning as needed */
  margin: 0;
  padding: 10px;
  background-color: rgba(
    255,
    255,
    255,
    0.3
  ); /* Add a background color for better visibility */
  width: 100%;
  text-align: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.product-line-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

/* contact */

.contact-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-title {
  display: flex;
  flex-direction: column;
}

.contact-title h1 {
  font-family: "Raleway", sans-serif;
  font-size: 2rem;
  color: #ffd460;
  font-weight: 600;
}

.contact-form {
  background-color: transparent;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  margin-left: 30px;
  width: 100%;
}

.contact-form h1 {
  font-family: "Raleway", sans-serif;
  font-size: 2rem;
  color: #ffd460;
  font-weight: bold;
  text-transform: uppercase;
}

.contact-form .input-text {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin-top: 50px;
}

.contact-form .input-text .emphone {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.contact-form input {
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: #ffd460;
  border-width: 2px;
  color: #ffd460;
  font-size: 20px;
  font-family: "Kaisei Decol", sans-serif;
}

.contact-form textarea {
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-width: 2px;
  border-color: #ffd460;
  height: 100px;
  color: #ffd460;
  font-size: 20px;
  font-family: "Kaisei Decol", sans-serif;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #ffd460;
  font-size: 15px;
  font-family: "Kaisei Decol", sans-serif;
  opacity: 0.8;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-width: 2px;
}

.contact-form button {
  background-color: transparent;
  border-radius: 20px;
  border: 2px solid #ffd460;
  height: 50px;
  font-family: "Raleway", sans-serif;
  color: #ffd460;
  font-size: 15px;
  margin-top: 20px;
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  font-weight: 400;
}

.marquee {
  position: relative;
  background-color: #ffd460;
  color: #2d4059;
  /* padding: 30px 0; */
  font-weight: 600;
  font-size: 100px;
  white-space: nowrap;
  text-transform: uppercase;
  overflow: hidden;
  width: 100%;
}

.marquee__inner {
  display: flex;
  width: fit-content;
  flex: auto;
  flex-direction: row;
  gap: 30px;
}

.marquee__part {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0 4px;
}

.foot-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.foot-contact {
  display: flex;
  flex-direction: column;
  margin-left: 300px;
  gap: 1rem;
  color: #ffd460;
  /* width: 450px; */
}

.foot-contact h1 {
  font-family: "Raleway", sans-serif;
  font-size: 30px;
}

.icons {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: row;
  gap: 10px;
}

.icons svg {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.icons p {
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  color: #ffd460;
}

.icons a {
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  color: #ffd460;
  text-decoration: none;
}

.foot-kiri {
  display: flex;
  flex-direction: column;
}

.foot-bottom {
  display: flex;
  flex-direction: column;
}

.line-bawah {
  width: 150px;
  height: 1px;
  background-color: #ffd460;
  margin-top: 20px;
}

.bahasa {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 30px;
}

.bahasa button {
  text-decoration: none;
  color: #ffd460;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0 0 10px;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

.line-bahasa {
  width: 1px;
  height: 15px;
  background-color: #ffd460;
  margin-top: 5px;
}

.foot-bottom a {
  text-decoration: none;
  color: transparent;
  font-size: 30px;
  padding: 0 0 10px;
  font-weight: 900;
  font-family: "Raleway", sans-serif;
  -webkit-text-stroke-color: #ffd460;
  -webkit-text-stroke-width: 0.5px;
}

.copy-right {
  display: flex;
  flex-direction: row;
  margin-top: 180px;
  justify-content: space-between; /* Menggunakan space-between agar elemen berada di kiri dan tengah */
}

.foot-copyrights {
  margin: auto; /* Center the element horizontally */
  text-align: center;
}

.foot-copyrights p {
  color: #ffd460;
  font-family: "Kaisei Decol", sans-serif;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .preloader {
    width: 100%;
    height: 100vh;
  }
  .App {
    padding: 2rem 3rem;
    width: 100%;
  }

  .product {
    padding: 2rem 1rem;
    width: 100%;
  }

  .about {
    padding: 2rem 2rem;
    width: 100%;
  }

  .contact {
    padding: 2rem 2rem;
    width: 100%;
  }

  .foot {
    padding: 2rem 3rem;
    width: 100%;
  }

  .preloader-content p {
    font-size: 100px;
    color: transparent;
    -webkit-text-stroke: 2px white;
    opacity: 0.5;
  }

  .indoText {
    font-size: medium;
    margin-top: 5px;
  }

  .about {
    height: 100vh;
  }

  .navbar {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100px;
    right: 80px;
    width: 150px;
    height: 150px;
    background-color: #2d4059;
    z-index: 9999;
    border-radius: 5px;
  }

  .navbar li {
    list-style-type: none;
    text-transform: uppercase;
    border: 0;
    font-size: 20px;
    font-weight: 700;
  }

  .navbar a {
    text-decoration: none;
    color: #ffd460;
    font-family: "Raleway", sans-serif;
    text-align: center;
  }

  .navbar.show {
    display: flex;
  }

  .menu-icon {
    display: block;
  }

  .header .logo img {
    width: 75px;
  }

  .content {
    padding-left: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .footer .rightfooter p {
    width: fit-content;
    font-size: 10px;
  }

  .footer .rightfooter .readMore {
    font-size: 7px;
  }

  .about-content p {
    font-size: 3.5vh;
  }

  /* product */
  .product .product-title p {
    font-family: "Kaisei Decol", sans-serif;
    font-size: 10vw;
    color: #ffd460;
    font-weight: bold;
  }

  /* Product item styles */
  .product-line-content-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* margin: 0 30px; */
  }

  .product-line-content-wrap .product-line-content {
    width: 100%; /* Make the container full width */
    max-width: 300px; /* Set a maximum width */
    height: 440px; /* Allow the height to adjust based on content */
  }

  .product-line-content-wrap .product-line-description {
    width: 100%; /* Make the container full width */
    max-width: 300px; /* Set a maximum width */
    height: 340px; /* Allow the height to adjust based on content */
  }

  /* contact */
  .contact-content {
    display: none;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: 0;
  }

  .submission-message {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .contact-form .input-text .emphone {
    flex-direction: column;
    gap: 1rem;
  }

  .contact-form .input-text .emphone input:nth-child(2) {
    margin-top: 50px;
  }

  .contact-form input,
  .contact-form textarea {
    width: 300px;
    box-sizing: border-box;
  }

  .contact-form button {
    width: 100%;
    height: 40px;
    font-size: 15px;
  }

  .contact-form textarea {
    height: 100px;
  }

  /* fooyter */
  .foot {
    height: 100%;
    padding: 2rem;
  }

  .foot-content {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .foot-contact {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    margin-left: 0;
    gap: 1rem;
    color: #ffd460;
  }

  .foot-contact a {
    width: 200px;
  }

  .copy-right {
    display: flex;
    flex-direction: row;
    margin-top: 100px;
  }

  .copy-right p {
    font-size: 10px;
  }

  .icons p {
    font-size: 20px;
  }

  .icons a {
    font-size: 15px;
  }

  .foot-copyrights p {
    font-size: 10px;
  }

  /* bottom bar */
}
